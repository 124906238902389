import React, { useState } from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import './App.css';
import IRF from "./Pages/InventoryReportForm.js";


const App = () => {

  return (
          <Routes>
            <Route index element={<IRF />} />
          </Routes>
  )
}

export default App
