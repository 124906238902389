import React, { useState } from 'react'
import Button from '@mui/material/Button'
import DropdownMenu from '../Components/Dropdown_Menu.jsx';
import { TextField, IconButton } from '@mui/material';
import './pageStyles.css';
import AmountCounter from '../Components/Amount_Counter.js';
import ScrollableTextField from '../Components/Scrollable_TextField.js';

const IRF = (props) => {
    const resourceMenuItems = ["Fabuloso", "Bleach", "Gloves", "Windex"];

    const [resource, updateResource] = useState('');
    const [amount, updateAmount] = useState(0);
    const [ID, updateID] = useState('');

    const submit = () => {
        updateAmount(0);
        updateResource('');
      }
    return (
        <div className='PagePlacement'>
            <div className='IRPage'>

                <label style={{ fontSize: '30px' }} children='Inventory Report Form' />

                <hr />

                <DropdownMenu
                    label="Resource"
                    defaultValue={props.resource}
                    handler={updateResource}
                    menu_items={resourceMenuItems} />

                <hr />

                <AmountCounter handler={updateAmount} amount={amount}/>

                <hr />

                <TextField onChange={updateID} variant='outlined' label='Employee ID Number ' size='small' fullWidth />

                <hr />

                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={submit}>
                    Add
                </Button>


            </div>
        </div>
    )
}

export default IRF

// class InventoryReportPage extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             resource: this.props.resource,
//             amount: 1,
//             ID: "",
//             amountMenuItems: Array(100).fill(1).map((n, i) => n + i),
//             resourceMenuItems: [ "Fabuloso", "Bleach", "Gloves", "Windex"]
//         }
//     }

//     handleIDChange = (event) => {
//         this.setState({ ID: event.target.value })
//     }

//     handleResourceChange = (event) => {
//         this.setState({ resource: event.target.value })
//     }

//     handleAmountChange = (event) => {
//         this.setState({ amount: event.target.value })
//     }

//     render() {
//         return (
//             <div
//                 style={{
//                     position: 'absolute',
//                     top: '0',
//                     bottom: '0',
//                     left: '0',
//                     right: '0',
//                     display: 'grid',
//                     justifyContent: 'center',
//                     alignItems: 'center'
//                 }}
//             >
//                 <div
//                     style={{
//                         padding: 20,
//                         background: '#fff',
//                         borderRadius: '2px',
//                         display: 'inline-block',
//                         minHeight: '150px',
//                         margin: '1rem',
//                         position: 'relative',
//                         minWidth: '250px',
//                         boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
//                         justifySelf: 'center',
//                     }}
//                 > <div id="inventory_report_form">
//                         <div
//                             style={{
//                                 alignItems: 'center',
//                                 fontSize: '30px'
//                             }}>Inventory Report Form</div>
//                         <hr />
//                         <DropdownMenu
//                             label="Resource"
//                             defaultValue={this.props.resource}
//                             handler={this.handleResourceChange}
//                             menuItems={this.state.resourceMenuItems} />
//                         <hr />
//                         <DropdownMenu
//                             label= "Amount Added/Removed"
//                             defaultValue={this.state.amount}
//                             handler={this.handleAmountChange}
//                             menuItems={this.state.amountMenuItems} />
//                         <hr />
//                         <TextField onChange = {this.handleIDChange} variant='outlined' label = 'Employee ID Number ' size = 'small' fullWidth/>

//                         <div
//                             style={{
//                                 alignItems: 'center',
//                                 paddingTop: '10px'
//                             }}>
//                             <Button
//                                 type='submit'
//                                 variant='contained'
//                                 color='primary'
//                                 size='small'>
//                                 Submit
//                             </Button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

// export default InventoryReportPage;