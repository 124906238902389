import React, { useState } from 'react'
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { TextField, IconButton } from '@mui/material';


const AmountCounter = ({handler, amount, negative = false, min_value, state = false }) => {

    const removeHandler = (amount, negative) => {
        if (!negative && amount <= min_value) {
            amount = handler(amount)
        } else {
            amount = handler(amount - 1);
        }
    }

    return (
        
        <Stack direction='row' style={{justifyContent:'center', width: '100%'}} >
            <TextField
                label='Amount'
                value={amount}
                variant='outlined'
                size='small'
                inputProps={{
                    min: 0, 
                    style: { textAlign: 'center'},
                    maxLength: 3
                }}
                style ={{width: '100%', textAlign: 'center'}}
                disabled={state}
             />

            <IconButton aria-label='add' onClick={() => handler(amount + 1)} children={<AddIcon />} disabled={state} />

            <IconButton aria-label='remove' onClick={() => removeHandler(amount, negative)} children={<RemoveIcon />} disabled={state} />

            <IconButton aria-label='reset' onClick={() => handler(min_value)} children={<RestartAltIcon />} disabled={state} />

        </Stack>

    )
}

export default AmountCounter